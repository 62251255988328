import ScheduleEditor from "@solvemd/schedule-editor/dist/ScheduleEditor";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export default function ScheduleWizard({ postSchedule, translations = {} }) {
    const [showWizard, setShowWizard] = useState(false);

    const newSchedule = () => {
        return (
            <div className="scheduleWizard__newSchedule">
                <ScheduleEditor
                    translations={translations}
                    createMode={true}
                    onScheduleCreateClick={(value) => {
                        return postSchedule(value, setShowWizard);
                    }}
                    onCreationCancelClick={() => {
                        setShowWizard(false);
                    }}
                />
            </div>
        );
    };
    const createScheduleButton = () => {
        return (
            <div className="scheduleWizard__createSchedule">
                <Button
                    variant="success"
                    onClick={() => {
                        setShowWizard(true);
                    }}
                >
                    <FormattedMessage id="timelineWidget.create" />
                </Button>
            </div>
        );
    };
    return (
        <div className="scheduleWizard">
            {showWizard ? newSchedule() : createScheduleButton()}
        </div>
    );
}
