import { FormattedMessage } from "react-intl";
import { getPatientAge, getTimezone, getVisitTime } from "../../helpers/visit";
import { selectFilter } from "react-bootstrap-table2-filter";

const statusHeaderFormatter = (column, colIndex, { filterElement }) => {
    return (
        <>
            <FormattedMessage id="calender.status" />
            {filterElement}
        </>
    );
};

const VisitTypeHeaderFormatter = (column, colIndex, { filterElement }) => {
    return (
        <>
            <FormattedMessage id="calender.visitType" />
            {filterElement}
        </>
    );
};

const statusFormatter = (cell) => {
    return (
        <div className="spacer">
            <div
                className={`text-success ${
                    cell === "scheduled" ? "scheduled" : ""
                }`}
            >
                {cell}
            </div>
            <span>Exam #5 </span>
        </div>
    );
};

const visitTypeFormatter = (cell, row) => {
    return (
        <div className="spacer">
            Follow Up
            <br />
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-clock"
                viewBox="0 0 16 16"
            >
                <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
            </svg>
            <span className="pl-1">
                {getPatientAge(row.user_dob)} &nbsp;|&nbsp; {getTimezone()}{" "}
                {getVisitTime(row.visit_start, row.visit_end)}
            </span>
        </div>
    );
};

const paymentsFormatter = (cell, row) => (
    <div className="spacer">
        Collected $40.00
        <br />
        <span>
            Copay $45 &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp; balance $110
        </span>
    </div>
);

const paymentsHeaderFormatter = (cell, row) => (
    <div className="spacer">
        <FormattedMessage id="calender.payments" />
    </div>
);

const patientFormatter = (cell, row) => (
    <div className="spacer">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-person-fill float-left mt-2 mr-2"
            viewBox="0 0 16 16"
        >
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
        </svg>
        {`${row.user_first_name} ${row.user_last_name}`}
        <br />
        <span>M | {getPatientAge(row.user_dob)} | HGKADLK</span>
    </div>
);

const patientHeaderFormatter = (column, colIndex) => (
    <FormattedMessage id="calender.patient" />
);

const renderingProviderFormatter = (cell, row) => (
    <div className="spacer">
        Dr. Michael
        <br />
        <span>on schedule </span>
    </div>
);

const renderingProviderHeaderFormatter = (column, colIndex) => (
    <FormattedMessage id="calender.renderingProvider" />
);
const statusVisitTypes = [
    { value: "", label: "Visit Type" },
    { value: "1", label: "type 1" },
    { value: "2", label: "type 2" },
    { value: "3", label: "type 3" },
];

const statusSelectOptions = [
    { value: "", label: "Status" },
    { value: "completed", label: "completed" },
    { value: "arrived", label: "arrived" },
    { value: "scheduled", label: "scheduled" },
];

export const columns = (setStatus) => {
    return [
        {
            dataField: "status",
            text: "User Status",
            sort: true,
            headerFormatter: statusHeaderFormatter,
            formatter: statusFormatter,
            filter: selectFilter({
                options: statusSelectOptions,
                withoutEmptyOption: true,
            }),
        },
        {
            dataField: "user_first_name",
            text: "Patient",
            sort: true,
            headerFormatter: patientHeaderFormatter,
            formatter: patientFormatter,
        },
        {
            dataField: "rendering",
            text: "Rendering Provider",
            sort: true,
            headerFormatter: renderingProviderHeaderFormatter,
            formatter: renderingProviderFormatter,
        },
        {
            dataField: "visit",
            text: "Visit Type",
            sort: true,
            headerFormatter: VisitTypeHeaderFormatter,
            formatter: visitTypeFormatter,
            filter: selectFilter({
                options: statusVisitTypes,
                withoutEmptyOption: true,
            }),
        },
        {
            dataField: "Payments",
            text: "Payments",
            sort: true,
            headerFormatter: paymentsHeaderFormatter,
            formatter: paymentsFormatter,
        },
    ];
};
