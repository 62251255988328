import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const propTypes = {
    href: PropTypes.string,
    navClass: PropTypes.string,
    eventKey: PropTypes.string,
    infoText: PropTypes.string.isRequired,
};

function OverlayComponent(props) {
    const renderPopover = (infoText) => (
        <Popover id="popover-basic">
            <Popover.Content className="popover" id="popover-positioned-right">
                <span className="text-nowrap">
                    <FormattedMessage id={infoText} />
                </span>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderPopover(props.infoText)}
        >
            <div href={props.href} className={props.navClass}>
                {props.children}
            </div>
        </OverlayTrigger>
    );
}

OverlayComponent.propTypes = propTypes;

export default OverlayComponent;
