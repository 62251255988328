import ScheduleEditor from "@solvemd/schedule-editor";
import React, { useState, useEffect } from "react";
import { BookedSlotsService } from "../../services/Resources";
import moment from "moment";

export default function ScheduleWrapper({
    schedule,
    updateSchedule,
    translations,
    date,
}) {
    const [slots, setSlots] = useState([]);
    useEffect(() => {
        BookedSlotsService.get(
            {
                params: {
                    start_date_time: moment(date).format("YYYY-MM-DD"),
                    schedule_id: schedule.uuid,
                    ordering: "start_date_time",
                },
            },
            (res) => {
                setSlots(res.results);
            }
        );
    }, [date, schedule.uuid]);
    return (
        <>
            <ScheduleEditor
                schedule={schedule}
                onScheduleUpdateClick={updateSchedule}
                translations={translations}
                slots={slots}
            />
            <div className="timezoneTimeline">
                <a>Timezone:</a>
                {schedule.timezone}
            </div>
        </>
    );
}
