export const detailsView = (data) => {
    return [
        {
            component: <p>{data.first_name}</p>,
            tabName: "First name",
        },
        {
            component: <p>{data.last_name}</p>,
            tabName: "Last name",
        },
        {
            component: <p>{data.dob}</p>,
            tabName: "Day of birth",
        },
    ];
};
