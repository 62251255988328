import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { AppointmentService } from "../../services/Resources";
import AppointmentEvent from "./appointmentEvent/AppointmentEventComponent";
import moment from "moment";

function renderEventContent(eventInfo) {
    return <AppointmentEvent eventInfo={eventInfo} />;
}

function appointmentToCalendarEvent(appointment) {
    const {
        user_first_name,
        user_last_name,
        user_dob,
        visit_start,
        visit_end,
        status,
    } = appointment;

    const startTime = moment(visit_start).format("LT");
    const endTime = moment(visit_end).format("LT");

    return {
        start: visit_start,
        end: visit_end,
        fullDay: false,
        extendedProps: {
            dateOfBirth: user_dob,
            firstName: user_first_name,
            lastName: user_last_name,
            startTime,
            endTime,
            status,
        },
    };
}

function CalendarComponent(props) {
    const [weekStarts, setWeekStarts] = useState("");
    const [weekEnds, setWeekEnds] = useState("");
    const [appointments, setAppointments] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [nextDataPage, setNextDataPage] = useState(true);

    useEffect(() => {
        let isMounted = true;
        const fetchAppointments = () => {
            if (!!weekStarts && !!weekEnds) {
                if (nextDataPage) {
                    AppointmentService.get(
                        {
                            params: {
                                enabled: true,
                                start_date: weekStarts,
                                end_date: weekEnds,
                                page: page,
                            },
                        },
                        (response) => {
                            if (isMounted) {
                                const itemCount =
                                    response.results.length + count;
                                setCount(itemCount);
                                const result = response.results.map((el) =>
                                    appointmentToCalendarEvent(el)
                                );
                                setAppointments([...appointments, ...result]);
                                if (response.count <= itemCount) {
                                    setNextDataPage(false);
                                } else {
                                    setPage(page + 1);
                                    setNextDataPage(true);
                                }
                            }
                        }
                    );
                }
            }
        };

        fetchAppointments();

        return () => {
            isMounted = false;
        };
    }, [weekStarts, weekEnds, page, appointments, nextDataPage, count]);

    function onDatesChanged(info) {
        setWeekStarts(info.start);
        setWeekEnds(info.end);
    }

    return (
        <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridWeek"
            events={appointments}
            eventContent={renderEventContent}
            datesSet={onDatesChanged}
        />
    );
}

export default CalendarComponent;
