import { LOCALES } from "../constants";

export const English = {
    [LOCALES.ENGLISH]: {
        buttons: {
            login: "Login",
            logout: "Logout",
        },
        kiosk: {
            username: "Username",
            password: "Password",
            new: "Create New Account",
            forgot: "Forgot password?",
            searchInput: "Search...",
            popup: {
                timeout: "Timeout in",
                seconds: "Seconds",
                continue: "Continue",
            },
        },
        errors: {
            usernameError: "Invalid Username",
            usernameEmpty: "Username Required",
            passwordEmpty: "Password Required",
            popup: {
                loginFailedTitle: "Login Failed",
                loginFailed: "Please enter a correct username and password. ",
            },
        },
        menu: {
            home: "Home",
            homepage: "Home Page",
            activeQueue: "Active Queue",
            calendar: "Calendar",
            person: "Person",
            settings: "Settings",
            timeline: "Timeline",
            logout: "Logout",
            oncall: "On Call",
        },
        hiddenMenu: {
            options: {
                one: "Sub Option 1",
                two: "Sub Option 2",
                three: "Sub Option 3",
                four: "Sub Option 4",
            },
        },
        calender: {
            today: "Today",
            queue: "in Queue Checked-in",
            status: "Status",
            patient: "Patient",
            renderingProvider: "Rendering Provider",
            visitType: "Visit Type",
            payments: "Payments",
            actions: {
                action: "Actions",
                actionOne: "action 1",
                actionTwo: "action 2",
                actionThree: "action 3",
            },
            dropdownItem: {
                status: {
                    all: "all statuses",
                    scheduled: "scheduled",
                    completed: "completed",
                    arrived: "arrived",
                },
                visitType: {
                    one: "Status 1",
                    two: "Status 2",
                    three: "Status 3",
                },
            },
        },
        expandRow: {
            tabsName: {
                progress: {
                    name: "Progress",
                    button: "Show more",
                },
            },
        },
        workflowStep: {
            service: "Service",
            location: "Location",
            time: "Time",
            registration: "Register",
            confirmation: "Confirmed",
            lookup: "Lookup",
        },
        timelineWidget: {
            // Put here only key-value pairs, do not store wrapper objects
            ok: "Ok",
            scheduleUpdateError:
                "Failed to update the schedule. Please try again later.",
            scheduleCreateError:
                "Failed to create the schedule. Please try again later.",
            create: "Create",
            update: "Update",
            edit: "Edit",
            datePreview: "Date",
            cancel: "Cancel",
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday",
            cancelModalBody:
                "You have unsaved changes, if you cancel the edit, you will lose them irretrievably",
            cancelModalAbort: "Go back to editing",
            cancelModalProceed: "Discard changes",
        },
        settings: {
            changeLang: "change language",
            french: "Français",
            german: "Deutsche",
            spanish: "Española",
            english: "English",
            polish: "Polski",
            japanese: "日本語",
            calendar: {
                save: "Save",
                availableColumns: "Available columns",
                selectedColumns: "Selected columns",
            },
        },
    },
};
