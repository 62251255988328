import { LoginService } from "../../services/Resources";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export function login(payload) {
    return { type: LOGIN, payload };
}
export function logout() {
    return { type: LOGOUT };
}

export function loggingIn(payload) {
    return async (dispatch) => {
        let formData = {
            ...payload,
            grant_type: "password",
            client_id: process.env.REACT_APP_CLIENT_ID,
        };

        let data = Object.keys(formData)
            .map((key) => `${key}=${encodeURIComponent(formData[key])}`)
            .join("&");

        const options = {
            params: {
                enabled: undefined,
            },
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data,
        };
        await LoginService.post(options, (res) => {
            localStorage.setItem("access", res.access_token);
            localStorage.setItem("refresh", res.refresh_token);
            dispatch(login(res));
        });
    };
}

export function loggingOut(payload) {
    return (dispatch) => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        dispatch(logout());
    };
}

export function setTokens(payload) {
    return (dispatch) => {
        dispatch(login(payload));
    };
}
