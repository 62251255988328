import React, { Fragment } from "react";
import { IntlProvider } from "react-intl";
import flatten from "flat";
import { messages } from "./messages/index";

const Provider = ({ children, locale }) => {
    return (
        <IntlProvider
            textComponent={Fragment}
            locale={locale}
            messages={flatten(messages[locale])}
        >
            {children}
        </IntlProvider>
    );
};

export default Provider;
