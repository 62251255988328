import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { DragSource, DropTarget } from "react-dnd";

const TableSettingsHeaderItem = forwardRef(function Card(
    { text, isDragging, connectDragSource, connectDropTarget },
    ref
) {
    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);
    const opacity = isDragging ? 0 : 1;
    useImperativeHandle(ref, () => ({
        getNode: () => elementRef.current,
    }));
    return (
        <div
            className="draggable-column-name"
            ref={elementRef}
            style={{ opacity }}
        >
            <div className="drag-icon"></div>
            {text}
        </div>
    );
});

export default DropTarget(
    "HEADER_POSITION",
    {
        hover(props, monitor, component) {
            if (!component) {
                return null;
            }
            const node = component.getNode();
            if (!node) {
                return null;
            }
            const dragIndex = monitor.getItem().index;
            const hoverIndex = props.index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = node.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            props.moveItem(dragIndex, hoverIndex);
            monitor.getItem().index = hoverIndex;
        },
    },
    (connect) => ({
        connectDropTarget: connect.dropTarget(),
    })
)(
    DragSource(
        "HEADER_POSITION",
        {
            beginDrag: (props) => ({
                id: props.id,
                index: props.index,
            }),
        },
        (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging(),
        })
    )(TableSettingsHeaderItem)
);
