import React, { useCallback, useEffect, useState } from "react";
import { WorkflowService } from "../../../../services/Resources";
import Workflow from "./workflow";

const WorkflowProgress = ({ workflowsURL }) => {
    const [workflows, setWorkflows] = useState([]);
    const [reload, setReload] = useState(true);

    const fetchWorkflows = useCallback(() => {
        if (reload) {
            setWorkflows([]);
            try {
                workflowsURL.map((workflow) => {
                    const workflowChunks = workflow.split("/");
                    const data = WorkflowService.get({
                        params: {
                            id: workflowChunks[workflowChunks.length - 2]
                        }
                    }, (data) => {
                        setWorkflows((prev) => [...prev, data]);
                    });
                    return data;
                });
            } catch (error) {
                console.log(error);
            }
        }
    }, [workflowsURL, reload]);

    useEffect(() => {
        setReload(false);
        fetchWorkflows();
    }, [fetchWorkflows]);

    const displayWorkFlows = workflows.map((workflow) => (
        <Workflow workflow={workflow} key={workflow.uuid} />
    ));

    return <div className="d-flex flex-column">{displayWorkFlows}</div>;
};

export default WorkflowProgress;
