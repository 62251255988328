import React from "react";

function PageComponent(props) {
    return (
        <>
            <div className="flex-column w-100 pl-3 pr-3">
                <div className="align-self-start">{props.header}</div>
                <div className="align-content-center">{props.content}</div>
            </div>
        </>
    );
}

export default PageComponent;
