import React, { useCallback, useEffect, useState } from "react";
import { LocationUserService } from "../../services/Resources";
import Table from "../../components/table/Table";
import { CollapsibleWrapper } from "../timeline/CollapsibleWrapper";

const OncallComponent = ({ location }) => {
    const [data, setData] = useState({
        count: 0,
        data: [],
    });
    const [filters, setFilters] = useState({
        page: 0,
        status: "",
        search: "",
        ordering: "",
    });
    const [hasBeenOpened, setHasBeenOpened] = useState(false);
    const [loading, setLoading] = useState(true);
    const pageSize = 20;

    const columns = [
        {
            dataField: "uuid",
            text: "ID",
            sort: true,
            hidden: true,
        },
        {
            dataField: "user_first_name",
            text: "First Name",
            sort: true,
        },
        {
            dataField: "user_last_name",
            text: "Last Name",
            sort: true,
        },
        {
            dataField: "user.home_phone",
            text: "Home phone",
            sort: true,
        },
        {
            dataField: "user.mobile_phone",
            text: "Mobile phone",
            sort: true,
        },
        {
            dataField: "user.email",
            text: "Email",
            sort: true,
        },
    ];

    const loadData = () => {
        const requestFilters = { ...filters };
        delete requestFilters.reload;
        return LocationUserService.get(
            {
                params: {
                    action: "oncall",
                    ...requestFilters,
                    limit: pageSize,
                    location__uuid: location.uuid,
                    offset: filters.page * pageSize,
                },
            },
            (response) => {
                setData((prevState) => {
                    const users = {
                        count: response.count,
                    };
                    if (filters.reload) {
                        users.data = response.results;
                    } else {
                        users.data = prevState.data.concat(response.results);
                    }

                    return users;
                });
                setLoading(false);
            },
            (err) => console.log(err)
        );
    };

    useEffect(() => {
        loadData();
    }, []); // eslint-disable-line

    return (
        <div className="container-xxl m-4">
            <CollapsibleWrapper
                name={location?.name + " [" + location?.address + "]"}
                onOpen={() => {
                    setHasBeenOpened(true);
                }}
            >
                <Table
                    data={data}
                    columns={columns}
                    loading={loading}
                    dataFilters={filters}
                    setFilters={setFilters}
                />
            </CollapsibleWrapper>
        </div>
    );
};

export default OncallComponent;
