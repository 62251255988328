import React from "react";
import ReactDOM from "react-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/style.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LanguageProvider from "./containers/languageProvider/LanguageProvider";
import { Provider } from "react-redux";
import store from "./redux/store";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const VERSION = process.env.REACT_APP_VERSION;

if (ENVIRONMENT !== "local") {
    Sentry.init({
        dsn:
            "https://b64ae2ac59bd4f06b4222a5607d7c82a@o995024.ingest.sentry.io/5953777",
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: ENVIRONMENT,
    });
    Sentry.setTag("app-version", VERSION);
}

ReactDOM.render(
    <Provider store={store}>
        <LanguageProvider>
            <App />
        </LanguageProvider>
    </Provider>,
    document.getElementById("root")
);

reportWebVitals();
