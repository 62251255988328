import React from "react";
import PageComponent from "../../components/page/PageComponent";
import HeaderComponent from "../../components/header/HeaderComponent";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import AppointmentView from "./AppointmentView";
import "moment-timezone";


function ActiveQueueContainer(props) {
    let today = moment().format("MMM DD yyyy");
    const header = (
        <HeaderComponent>
            <span className="active-queue-span-1">
                <FormattedMessage id="menu.activeQueue" />
            </span>
            <span>
                <FormattedMessage id="calender.today" /> | {today}
            </span>
            <span className="active-queue-span-3">
                <FormattedMessage id="calender.queue" />
            </span>
        </HeaderComponent>
    );
    const cuttentTzDate = moment.tz("Canada/Central");

    const content = <AppointmentView 
        startDate={cuttentTzDate.startOf("day").toISOString(true)}
        endDate={cuttentTzDate.endOf("day").toISOString(true)}
    />;

    return (
        <div className="w-100">
            <PageComponent header={header} content={content} />
        </div>
    );
}

export default ActiveQueueContainer;
