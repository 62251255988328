import { DEFAULT_LANGUAGE } from "../../i18n/configuration";
import { SET_LANGUAGE } from "../actions/language.actions";

const INITIAL_STATE = {
    selectedLanguage: DEFAULT_LANGUAGE.ENGLISH,
    isFetching: true,
    isLogin: false,
};

const languageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_LANGUAGE:
            return {
                ...state,
                isFetching: false,
                selectedLanguage: action.payload,
            };
        default:
            return state;
    }
};

export default languageReducer;
