import WorkflowProgressView from "./workflowProgress/workflowProgressView";
import { FormattedMessage } from "react-intl";

export const detailsView = (data) => {
    return [
        {
            component: <WorkflowProgressView workflowsURL={data.workflows} />,
            tabName: <FormattedMessage id="expandRow.tabsName.progress.name" />,
        },
    ];
};
