import React, { useEffect, useState, useCallback } from "react";
import SearchBar from "../../components/search/SearchBar";
import Table from "../../components/table/Table";
import { AppointmentService } from "../../services/Resources";
import { columns } from "./columns";
import { detailsView } from "./expandRow";

const AppointmentView = (props) => {
    const [data, setData] = useState({
        count: 0,
        data: [],
    });
    const [filters, setFilters] = useState({
        page: 0,
        status: "",
        search: "",
        ordering: "",
    });
    const [loading, setLoading] = useState(true);
    const pageSize = 20;

    const getData = useCallback(() => {
        const requestFilters = { ...filters };
        delete requestFilters.reload;
        return AppointmentService.get(
            {
                params: {
                    ...requestFilters,
                    limit: pageSize,
                    offset: filters.page * pageSize,
                    visit_start__gte: props.startDate,
                    visit_start__lte: props.endDate,
                },
            },
            (response) => {
                setData((prevState) => {
                    const appointments = {
                        count: response.count,
                    };
                    if (filters.reload) {
                        appointments.data = response.results;
                    } else {
                        appointments.data = prevState.data.concat(
                            response.results
                        );
                    }

                    return appointments;
                });
                setLoading(false);
            },
            (err) => console.log(err)
        );
    }, [filters, props.startDate, props.endDate]);

    useEffect(() => {
        getData();
    }, [getData]);

    const searchData = (e) => {
        setFilters({
            ...filters,
            search: e.target.value,
            reload: true,
            page: 0,
        });
    };

    return (
        <div className="container-xxl m-4">
            <SearchBar searchData={searchData} />
            <Table
                data={data}
                columns={columns()}
                loading={loading}
                dataFilters={filters}
                setFilters={setFilters}
                detailsView={detailsView}
            />
        </div>
    );
};

export default AppointmentView;
