import { UserSettingsService } from "../../services/UserSettings";
import { DEFAULT_LANGUAGE } from "../../i18n/configuration";

export const SET_LANGUAGE = "SET_LANGUAGE";

const fetchLanguage = (selectedLanguage) => ({
    type: SET_LANGUAGE,
    payload: selectedLanguage,
});

export const fetchLanguageAsyc = () => {
    return (dispatch) => {
        UserSettingsService.getItem("lang")
            .then((response) => {
                dispatch(fetchLanguage(response));
            })
            .catch((error) => console.log(error));
    };
};

export const setLanguage = (value = DEFAULT_LANGUAGE.ENGLISH) => {
    return (dispatch) => {
        UserSettingsService.setItem("lang", value).then(() => {
            dispatch(fetchLanguageAsyc());
        });
    };
};
