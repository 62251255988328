import React from "react";
import PageComponent from "../../components/page/PageComponent";
import HeaderComponent from "../../components/header/HeaderComponent";
import { FormattedMessage, FormattedDate } from "react-intl";
import moment from "moment";
import AppointmentView from "./AppointmentView";

function ArrivedQueueContainer(props) {
    let today = "Today | " + moment().format("MMM DD yyyy");
    const header = (
        <HeaderComponent>
            <span>
                <FormattedMessage id="calender.today" /> |{" "}
                <FormattedDate value={today} />
            </span>
        </HeaderComponent>
    );
    const content = <AppointmentView />;

    return (
        <div className="w-100">
            <PageComponent header={header} content={content} />
        </div>
    );
}

export default ArrivedQueueContainer;
