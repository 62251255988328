import React from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import { Spinner } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ExpandRowsView from "./expandRowsView";

const propTypes = {
    data: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    columns: PropTypes.array,
    expandRow: PropTypes.object,
    setFilters: PropTypes.func,
    dataFilters: PropTypes.object,
};

const TableView = ({
    data,
    loading,
    columns,
    setFilters,
    dataFilters,
    detailsView,
}) => {
    const handleTableSort = (type, { filters, sortField, sortOrder }) => {
        const requestFilters = {
            search: dataFilters.search,
            page: 0,
            reload: true,
        };

        const setRequestOrdering = () => {
            if (sortOrder === "desc")
                return (requestFilters["ordering"] = "-" + sortField);
            else return (requestFilters["ordering"] = sortField);
        };

        const setRequestFilters = () => {
            return Object.entries(filters).map(
                ([filter, index]) => (requestFilters[filter] = index.filterVal)
            );
        };

        setRequestFilters();
        setRequestOrdering();
        setFilters(requestFilters);
    };

    const loadNextPage = () => {
        setFilters({
            ...dataFilters,
            page: dataFilters.page + 1,
            reload: false,
        });
    };

    const expandRow = () => ({
        renderer: (row) => {
            const id = row.uuid;
            return (
                <ExpandRowsView data={data} id={id} detailsView={detailsView} />
            );
        },
        onlyOneExpanding: true,
    });

    return (
        <InfiniteScroll
            dataLength={data.data.length}
            hasMore={data.count > data.data.length}
            loader={"Loading..."}
            next={loadNextPage}
        >
            <PaginationProvider
                pagination={paginationFactory({ custom: true })}
            >
                {({ paginationProps, paginationTableProps }) => (
                    <BootstrapTable
                        bordered={false}
                        keyField="uuid"
                        data={data.data}
                        columns={columns}
                        remote
                        bootstrap4
                        {...paginationTableProps}
                        expandRow={detailsView ? expandRow() : () => { }}
                        onTableChange={handleTableSort}
                        filter={filterFactory({ custom: true })}
                        noDataIndication={() => {
                            if (!loading) {
                                return <p>no data</p>;
                            } else {
                                return <Spinner animation="border" />;
                            }
                        }}
                    />
                )}
            </PaginationProvider>
        </InfiniteScroll>
    );
};

TableView.propTypes = propTypes;

export default TableView;
