import localforage from "localforage";

class UserSettings {
    constructor() {
        this.storage = localforage.createInstance({
            driver: [
                localforage.WEBSQL,
                localforage.INDEXEDDB,
                localforage.LOCALSTORAGE,
            ],
            name: "UserSettings",
        });
    }

    setItem = async (key, item) => {
        this._checkParameter(key);
        this._checkParameter(item);
        try {
            await this.storage.setItem(key, item);
        } catch {
            return false;
        }
    };

    getItem = async (key) => {
        this._checkParameter(key);
        try {
            const value = await this.storage.getItem(key);
            if (typeof value === "object") return false;
            return value;
        } catch {
            return false;
        }
    };

    deleteItem = async (key) => {
        this._checkParameter(key);
        try {
            await this.storage.removeItem(key);
        } catch {
            return false;
        }
    };

    clearSettings = async () => {
        try {
            await this.storage.clear();
        } catch {
            return false;
        }
    };

    _checkParameter = (param) => {
        if (typeof param !== "string") throw new TypeError();
    };
}

export const UserSettingsService = new UserSettings();
