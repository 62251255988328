import React, { useCallback, useEffect, useState } from "react";
import SearchBar from "../../components/search/SearchBar";
import Table from "../../components/table/Table";
import { UserService } from "../../services/Resources";
import { columns } from "./columns";
import { detailsView } from "./expandRow";

const UsersView = (props) => {
    const [data, setData] = useState({
        count: 0,
        data: [],
    });
    const [filters, setFilters] = useState({
        page: 0,
        status: "",
        search: "",
        ordering: "",
    });
    const [loading, setLoading] = useState(true);
    const pageSize = 20;

    const getData = useCallback(() => {
        const requestFilters = { ...filters };
        delete requestFilters.reload;
        return UserService.get(
            {
                params: {
                    ...requestFilters,
                    limit: pageSize,
                    offset: filters.page * pageSize,
                },
            },
            (response) => {
                setData((prevState) => {
                    const users = {
                        count: response.count,
                    };
                    if (filters.reload) {
                        users.data = response.results;
                    } else {
                        users.data = prevState.data.concat(response.results);
                    }

                    return users;
                });
                setLoading(false);
            },
            (err) => console.log(err)
        );
    }, [filters]);

    useEffect(() => {
        getData();
    }, [getData]);

    const searchData = (e) => {
        setFilters({ ...filters, search: e.target.value, reload: true });
    };

    return (
        <div className="container-xxl m-4">
            <SearchBar searchData={searchData} />
            <Table
                data={data}
                columns={columns}
                loading={loading}
                dataFilters={filters}
                setFilters={setFilters}
                detailsView={detailsView}
            />
        </div>
    );
};

export default UsersView;
