import React, { useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Nav } from "react-bootstrap";
import OverlayComponent from "./overlay/OverlayComponent";
import HiddenOptionsMenuComponent from "./hiddenOptionsMenu/HiddenOptionsMenuComponent";
import { NavStructure } from "./NavStructure";
import { loggingOut } from "../../redux/actions/user.actions";
import store from "../../redux/store";

function NavbarComponent(props) {
    const { pathname } = useLocation();
    let history = useHistory();

    let isShowHiddenMenu = false;

    useEffect(() => {
        if (pathname.includes("/logout")) {
            store.dispatch(loggingOut());
            history.push(`/`);
        }
    }, [history, pathname]);

    return (
        <Nav defaultActiveKey="/home" className="flex-column nav-container">
            <div className="nav-content">
                {NavStructure.map((element) => (
                    <div key={element[0].name}>
                        <OverlayComponent
                            navClass={"nav-" + element[0].infoText}
                            infoText={"menu." + element[0].infoText}
                        >
                            <Link to={element[0].link} className="nav-menu">
                                {element[0].icon}
                            </Link>
                        </OverlayComponent>
                        {pathname.includes(element[0].link) &&
                            ((isShowHiddenMenu = true),
                            (
                                <HiddenOptionsMenuComponent
                                    element={element[0]}
                                />
                            ))}
                    </div>
                ))}
                {!isShowHiddenMenu && (
                    <HiddenOptionsMenuComponent element={NavStructure[0][0]} />
                )}
            </div>
        </Nav>
    );
}

export default NavbarComponent;
