import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOCALES } from "../../i18n/constants";
import PageComponent from "../../components/page/PageComponent";
import { FormattedMessage } from "react-intl";
import { setLanguage } from "../../redux/actions/language.actions";
import TableSettingsComponent from "../../components/table/TableSettingsComponent";

const SettingsComponent = () => {
    const language = useSelector((state) => state.language.selectedLanguage);
    const dispatch = useDispatch();

    const optionsKey = Object.keys(LOCALES).reduce((array, key) => {
        return [...array, { key: LOCALES[key], name: key.toLowerCase() }];
    }, []);

    const langOptionsView = optionsKey.map((item) => (
        <FormattedMessage id={`settings.${item.name}`} key={item.key}>
            {(placeholder) => (
                <option disabled={language === item.key} value={item.key}>
                    {placeholder}
                </option>
            )}
        </FormattedMessage>
    ));

    const selectLang = (
        <>
            <form>
                <FormattedMessage id="settings.changeLang" />
                <select
                    value={language}
                    onChange={(e) => {
                        dispatch(setLanguage(e.target.value));
                    }}
                >
                    {langOptionsView}
                </select>
            </form>
            <TableSettingsComponent
                tableName="Queue"
                tableHeaders={[
                    "status",
                    "patient",
                    "renderingProvider",
                    "visitType",
                ]}
            />
        </>
    );

    return <PageComponent content={selectLang} />;
};

export default SettingsComponent;
