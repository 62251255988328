import React, { useState, useEffect } from "react";
import { LocationService } from "../../services/Resources";
import OncallComponent from "./OncallComponent";

export default function OncallContainer() {
    const [entities, setEntities] = useState(null);
    const [locations, setLocations] = useState(null);

    useEffect(() => {
        LocationService.get({}, (data) => {
            setLocations(data.results);
        });
    }, []);

    const renderLocationPreviews = () => {
        if (!locations) return null;
        return locations.map(location => {
            return <OncallComponent entities={entities} location={location} />
        })
    }

    return (
        <div className="timelineComponent">
            {renderLocationPreviews()}
        </div>
    );
}
