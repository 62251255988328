import React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { LOCALES } from "../../../../i18n/constants";
import { setLanguage } from "../../../../redux/actions/language.actions";

const LanguageSelect = ({ setIsOpen }) => {
    const language = useSelector((state) => state.language.selectedLanguage);
    const dispatch = useDispatch();

    const optionsKey = Object.keys(LOCALES).reduce((array, key) => {
        return [...array, { key: LOCALES[key], name: key.toLowerCase() }];
    }, []);

    const langOptions = optionsKey.map((item) => (
        <FormattedMessage id={`settings.${item.name}`} key={item.key}>
            {(placeholder) => (
                <>
                    {setIsOpen ? (
                        <div
                            disabled={language === item.key}
                            value={item.key}
                            onClick={() => {
                                dispatch(setLanguage(item.key));
                                if (setIsOpen) return setIsOpen(false);
                            }}
                        >
                            {placeholder}
                        </div>
                    ) : (
                        <option
                            disabled={language === item.key}
                            value={item.key}
                        >
                            {placeholder}
                        </option>
                    )}
                </>
            )}
        </FormattedMessage>
    ));

    const onlineView = (
        <div className="float-left ml-3">
            <select
                value={language}
                onChange={(e) => {
                    dispatch(setLanguage(e.target.value));
                }}
            >
                {langOptions}
            </select>
        </div>
    );

    const kioskView = <div className="langSelect">{langOptions}</div>;

    return <>{setIsOpen ? kioskView : onlineView}</>;
};

export default LanguageSelect;
