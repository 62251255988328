import React from "react";
import CmeterUI, { IconWrapper } from "@solvemd/cmeter-reactjs";
import { useSelector } from "react-redux";
import { ReactComponent as InternetIcon } from "./cmeter_imgs/internetIcon.svg";
import { ReactComponent as WifiIcon } from "./cmeter_imgs/wifiIcon.svg";

const CheckConnection = () => {
    const cmeterStatus = useSelector((state) => state.cmeter.cmeterStatus);

    const icons = [
        {
            name: "local-check",
            icon: (
                <IconWrapper>
                    <WifiIcon />
                </IconWrapper>
            ),
        },
        {
            name: "internet-check",
            icon: (
                <IconWrapper>
                    <InternetIcon />
                </IconWrapper>
            ),
        },
    ];
    return (
        <div className="check-connection">
            <CmeterUI cmeterStatus={cmeterStatus} icons={icons} />
        </div>
    );
};

export default CheckConnection;
