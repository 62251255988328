import { combineReducers } from "redux";

import languageReducer from "./reducers/language.reducer";
import userReducer from "./reducers/user.reducer";
import cmeterReducer from "./reducers/cmeter.reducer";

export default combineReducers({
    language: languageReducer,
    user: userReducer,
    cmeter: cmeterReducer,
});
