import React from "react";
import PageComponent from "../../components/page/PageComponent";
import { FormattedMessage } from "react-intl";

function Home(props) {
    const header = (
        <h1>
            <FormattedMessage id="menu.home" />
        </h1>
    );
    const content = (
        <h1>
            <FormattedMessage id="menu.homepage" />
        </h1>
    );

    return (
        <div>
            <PageComponent header={header} content={content} />
        </div>
    );
}

export default Home;
