import React from "react";

function HeaderComponent(props) {
    return (
        <header>
            <div className="w-100">
                <div className="row">{props.children}</div>
            </div>
            <hr />
        </header>
    );
}

export default HeaderComponent;
