import React from "react";
import PageComponent from "../../components/page/PageComponent";
import HeaderComponent from "../../components/header/HeaderComponent";
import { FormattedMessage } from "react-intl";
import CalendarComponent from "../../components/calendar/CalendarComponent";
import moment from "moment";

function CalendarContainer(props) {
    let today = moment().format("MMM DD yyyy");
    const header = (
        <HeaderComponent>
            <span className="active-queue-span-1">
                <FormattedMessage id="menu.activeQueue" />
            </span>
            <span>
                <FormattedMessage id="calender.today" /> | {today}
            </span>
            <span className="active-queue-span-3">
                <FormattedMessage id="calender.queue" />
            </span>
        </HeaderComponent>
    );

    const content = <CalendarComponent />;

    return (
        <div className="w-100">
            <PageComponent header={header} content={content} />
        </div>
    );
}

export default CalendarContainer;
