import { LOCALES } from "../constants";

export const Spanish = {
    [LOCALES.SPANISH]: {
        buttons: {
            login: "Acceso",
            logout: "Cerrar sesión",
        },
        kiosk: {
            username: "Nombre de usuario",
            password: "Contraseña",
            new: "Crear una nueva cuenta",
            forgot: "Se te olvidó tu contraseña ?",
            searchInput: "buscar...",
            popup: {
                timeout: "Tiempo de espera en",
                seconds: "Segundos",
                continue: "Continuar",
            },
        },
        errors: {
            usernameError: "Nombre de usuario no válido",
            usernameEmpty: "Nombre de usuario requerido",
            passwordEmpty: "Se requiere contraseña",
            popup: {
                loginFailedTitle: "Error de inicio de sesion",
                loginFailed:
                    "Por favor, introduzca un nombre de usuario y contraseña correctos.  ",
            },
        },
        menu: {
            home: "Hogar",
            homepage: "Pagina de inicio",
            activeQueue: "Cola activa",
            calendar: "Calendario",
            person: "Persona",
            settings: "Ajustes",
            timeline: "Cronología",
            logout: "Cerrar Sesión",
            oncall: "En llamada",
        },
        hiddenMenu: {
            options: {
                one: "Sub opción 1",
                two: "Sub opción 2",
                three: "Sub opción 3",
                four: "Sub opción 4",
            },
        },
        calender: {
            today: "Hoy dia",
            queue: "en cola Check-in",
            status: "Estado",
            patient: "Paciente",
            renderingProvider: "Proveedor de renderizado",
            visitType: "Tipo de visita",
            payments: "Pagos",
            actions: {
                action: "Acción",
                actionOne: "Acción 1",
                actionTwo: "Acción 2",
                actionThree: "Acción 3",
            },
            dropdownItem: {
                status: {
                    all: "todos los estados",
                    scheduled: "programado",
                    completed: "terminado",
                    arrived: "llegado",
                },
                visitType: {
                    one: "Estado 1",
                    two: "Estado 2",
                    three: "Estado 3",
                },
            },
        },
        expandRow: {
            tabsName: {
                progress: {
                    name: "Progreso",
                    button: "Mostrar más",
                },
            },
        },
        workflowStep: {
            service: "Servicio",
            location: "Localización",
            time: "Hora",
            registration: "Registrarse",
            confirmation: "Confirmado",
            lookup: "Buscar",
        },
        timelineWidget: {
            ok: "Ok",
            scheduleUpdateError:
                "No se pudo actualizar la programación. Por favor, inténtelo de nuevo más tarde.",
            scheduleCreateError:
                "No se pudo crear el horario. Por favor, inténtelo de nuevo más tarde.",
            create: "Crear",
            update: "Actualizar",
            edit: "Editar",
            datePreview: "Fecha",
            cancel: "Cancelar",
            monday: "Lunes",
            tuesday: "Martes",
            wednesday: "Miércoles",
            thursday: "Jueves",
            friday: "Viernes",
            saturday: "Sábado",
            sunday: "Domingo",
            cancelModalBody:
                "Tiene cambios sin guardar, si cancela la edición, los perderá irremediablemente ",
            cancelModalAbort: "Volver a editar",
            cancelModalProceed: "Descartar los cambios",
        },
        settings: {
            changeLang: "cambiar idioma",
            french: "Français",
            german: "Deutsche",
            spanish: "Española",
            english: "English",
            polish: "Polski",
            japanese: "日本語",
            calendar: {
                save: "Guardar",
                availableColumns: "Columnas disponibles",
                selectedColumns: "Columnas seleccionadas",
            },
        },
    },
};
