import { LOCALES } from "../constants";

export const French = {
    [LOCALES.FRENCH]: {
        buttons: {
            login: "Connexion",
            logout: "Se déconnecter",
        },
        kiosk: {
            username: "Nom d'utilisateur",
            password: "Mot de passe",
            new: "Créer un nouveau compte",
            forgot: "Mot de passe oublié?",
            searchInput: "Rechercher...",
            popup: {
                timeout: "Délai d'attente dans",
                seconds: "Seconds",
                continue: "Continuer",
            },
        },
        errors: {
            usernameError: "Nom d'utilisateur invalide",
            usernameEmpty: "Requis nom d'utilisateur",
            passwordEmpty: "Mot de passe requis",
            popup: {
                loginFailedTitle: "Échec de la connexion",
                loginFailed:
                    "Veuillez saisir un nom d'utilisateur et un mot de passe corrects. ",
            },
        },
        menu: {
            home: "Accueil",
            homepage: `Page d'accueil`,
            activeQueue: `File d'attente active`,
            calendar: "Calendrier",
            person: "La personne",
            settings: "Paramètres",
            timeline: "Chronologie",
            logout: "Se déconnecter",
            oncall: "De garde",
        },
        hiddenMenu: {
            options: {
                one: "Sous-option 1",
                two: "Sous-option 2",
                three: "Sous-option 3",
                four: "Sous-option 4",
            },
        },
        calender: {
            today: "Aujourd'hui",
            queue: "dans la file d'attente enregistré",
            status: "Statut",
            patient: "Patient/Patiente",
            renderingProvider: "Fournisseur de rendu",
            visitType: "Type de visite",
            payments: "Paiements",
            actions: {
                action: "Actions",
                actionOne: "action 1",
                actionTwo: "action 2",
                actionThree: "action 3",
            },
            dropdownItem: {
                status: {
                    all: "tous les statuts",
                    scheduled: "programmé",
                    completed: "complété",
                    arrived: "arrivée",
                },
                visitType: {
                    one: "Statut 1",
                    two: "Statut 2",
                    three: "Statut 3",
                },
            },
        },
        expandRow: {
            tabsName: {
                progress: {
                    name: "Le progrès",
                    button: "Montre plus",
                },
            },
        },
        workflowStep: {
            service: "Un service",
            location: "Emplacement",
            time: "Temps",
            registration: "S'inscrire",
            confirmation: "Confirmé",
            lookup: "Chercher",
        },
        timelineWidget: {
            ok: "Ok",
            scheduleUpdateError:
                "La planification n'a pas pu être mise à jour. Veuillez réessayer plus tard.",
            scheduleCreateError:
                "Échec de la création de l'horaire. Veuillez réessayer plus tard.",
            create: "Créer",
            update: "Mettre à jour",
            edit: "Éditer",
            datePreview: "Date",
            cancel: "Annuler",
            monday: "Lundi",
            tuesday: "Mardi",
            wednesday: "Mercredi",
            thursday: "Jeudi",
            friday: "Vendredi",
            saturday: "Samedi",
            sunday: "Dimanche",
            cancelModalBody:
                "Vous avez des modifications non enregistrées, si vous annulez la modification, vous les perdrez irrémédiablement ",
            cancelModalAbort: "Revenir à l'édition",
            cancelModalProceed: "Annuler les modifications",
        },
        settings: {
            changeLang: "changer de langue",
            french: "Français",
            german: "Deutsche",
            spanish: "Española",
            english: "English",
            polish: "Polski",
            japanese: "日本語",
            calendar: {
                save: "Enregistrer",
                availableColumns: "Colonnes disponibles",
                selectedColumns: "Colonnes sélectionnées",
            },
        },
    },
};
