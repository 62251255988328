import Cmeter, { LocalCheck, InternetCheck } from "@solvemd/cmeter";

export function createMeter() {
    return new Cmeter(config);
}
const API_URL = process.env.REACT_APP_API_URL;
export const config = {
    checks: [
        {
            name: "local-check",
            checkComponent: LocalCheck,
        },
        {
            name: "internet-check",
            checkComponent: InternetCheck,
            resources: [
                {
                    data: API_URL + "/api/",
                },
            ],
        },
    ],
};
