import { LOCALES } from "../constants";

export const POLISH = {
    [LOCALES.POLISH]: {
        buttons: {
            login: "Zaloguj się",
            logout: "Wyloguj",
        },
        kiosk: {
            username: "Użytkownik",
            password: "Hasło",
            new: "Stwórz nowe konto",
            forgot: "Zapomniałeś hasła?",
            searchInput: "Szukaj...",
            popup: {
                timeout: "Koniec czasu za",
                seconds: "sekund",
                continue: "kontynuuj",
            },
        },
        errors: {
            usernameError: "Nieprawidłowa nazwa użytkownika",
            usernameEmpty: "Wymagana nazwa użytkownika",
            passwordEmpty: "Wymagane hasło",
            popup: {
                loginFailedTitle: "Logowanie nie powiodło się",
                loginFailed: "Wprowadź poprawną nazwę użytkownika i hasło.",
            },
        },
        menu: {
            home: "Strona główna",
            homepage: "Strona główna",
            activeQueue: "Osoby oczekujące",
            calendar: "Kalendarz",
            person: "Osoba",
            settings: "Ustawienia",
            timeline: "Oś czasu",
            logout: "Wyloguj się",
            oncall: "Na dyżurze",
        },
        hiddenMenu: {
            options: {
                one: "Opcja 1",
                two: "Opcja 2",
                three: "Opcja 3",
                four: "Opcja 4",
            },
        },
        calender: {
            today: "Dziś jest",
            queue: "Osoby zapisane",
            status: "Status",
            patient: "Pacjent",
            renderingProvider: "Lekarz",
            visitType: "Tyb Wizyty",
            payments: "Płatności",
            actions: {
                action: "Działania",
                actionOne: "działanie 1",
                actionTwo: "działanie 2",
                actionThree: "działanie 3",
            },
            dropdownItem: {
                status: {
                    all: "wszystkie statusy",
                    scheduled: "planowy",
                    completed: "zakończony",
                    arrived: "przybył",
                },
                visitType: {
                    one: "Status 1",
                    two: "Status 2",
                    three: "Status 3",
                },
            },
        },
        expandRow: {
            tabsName: {
                progress: {
                    name: "Postęp",
                    button: "Pokaż więcej",
                },
            },
        },
        workflowStep: {
            service: "Usługa",
            location: "Lokalizacja",
            time: "Czas",
            registration: "Rejestracja",
            confirmation: "Potwierdzam",
            lookup: "Wyszukaj",
        },
        timelineWidget: {
            ok: "Ok",
            scheduleUpdateError:
                "Nie udało się zaktualizować harmonogramu. Spróbuj ponownie później.",
            scheduleCreateError:
                "Nie udało się utworzyć harmonogramu. Spróbuj ponownie później.",
            create: "Utwórz",
            update: "Zaktualizuj",
            edit: "Edytuj",
            datePreview: "Data",
            cancel: "Anuluj",
            monday: "Poniedziałek",
            tuesday: "Wtorek",
            wednesday: "Środa",
            thursday: "Czwartek",
            friday: "Piątek",
            saturday: "Sobota",
            sunday: "Niedziela",
            cancelModalBody:
                "Masz niezapisane zmiany, anulując edycję utracisz je bezpowrotnie",
            cancelModalAbort: "Wróć do edytowania",
            cancelModalProceed: "Odrzuć zmiany",
        },
        settings: {
            changeLang: "zmień język",
            french: "Français",
            german: "Deutsche",
            spanish: "Española",
            english: "English",
            polish: "Polski",
            japanese: "日本語",
            calendar: {
                save: "Zapisz",
                availableColumns: "Dostępne kolumny",
                selectedColumns: "Wybrane kolumny",
            },
        },
    },
};
