import { LOCALES } from "../constants";

export const JAPANESE = {
    [LOCALES.JAPANESE]: {
        buttons: {
            login: "ログイン",
            logout: "ログアウト",
        },
        kiosk: {
            username: "ユーザー名",
            password: "パスワード",
            new: "新しいアカウントを作る",
            forgot: "パスワードを忘れた場合",
            searchInput: "検索する",
            popup: {
                timeout: "タイムアウト",
                seconds: "秒",
                continue: "継続する",
            },
        },
        errors: {
            usernameError: "無効なユーザー名",
            usernameEmpty: "ユーザー名が必要です",
            passwordEmpty: "パスワードが必要",
            popup: {
                loginFailedTitle: "ログインに失敗しました",
                loginFailed: "正しいユーザー名とパスワードを入力してください。",
            },
        },
        menu: {
            home: "ホームページ",
            homepage: "ホームページ",
            activeQueue: "順番待ちの列",
            calendar: "カレンダー",
            person: "人",
            settings: "設定",
            timeline: "タイムライン",
            logout: "ログアウト",
            oncall: "電話で",
        },
        hiddenMenu: {
            options: {
                one: "オプション１",
                two: "オプション２",
                three: "オプション３",
                four: "オプション４",
            },
        },
        calender: {
            today: "今日",
            queue: "サインアップした患者",
            status: "ステータス",
            patient: "患者",
            renderingProvider: "医者",
            visitType: "訪問のタイプ",
            actions: {
                action: "アクション",
                actionOne: "アクション１",
                actionTwo: "アクション２",
                actionThree: "アクション３",
            },
            dropdownItem: {
                status: {
                    one: "ステータス１",
                    two: "ステータス２",
                    three: "ステータス３",
                },
                visitType: {
                    one: "ステータス１",
                    two: "ステータス２",
                    three: "ステータス３",
                },
            },
        },
        timelineWidget: {
            ok: "了解",
            scheduleUpdateError:
                "スケジュールの更新に失敗しました。後でもう一度やり直してください。",
            scheduleCreateError:
                "スケジュールの作成に失敗しました。後でもう一度やり直してください",
            create: "作成",
            update: "更新",
            edit: "編集",
            datePreview: "日付",
            cancel: "キャンセル",
            monday: "月曜",
            tuesday: "火曜日",
            wednesday: "水曜日",
            thursday: "木曜日",
            friday: "金曜日",
            saturday: "土曜日",
            sunday: "日曜日",
            cancelModalBody:
                "保存されていない変更があります。編集をキャンセルすると、取り返しのつかないほど変更が失われます。 ",
            cancelModalAbort: "編集に戻る ",
            cancelModalProceed: "変更を破棄",
        },
        settings: {
            changeLang: "言葉を選択",
            french: "Français",
            german: "Deutsche",
            spanish: "Española",
            english: "English",
            polish: "Polski",
            japanese: "日本語",
            calendar: {
                save: "設定を保存する",
                availableColumns: "使用可能な列",
                selectedColumns: "選択した列",
            },
        },
    },
};
