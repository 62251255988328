import React from "react";
import { FormattedMessage } from "react-intl";

const WorkflowStep = function (props) {
    return (
        <div
            className={`workflow-step ${
                props.status === "completed" ? "completed" : ""
            }`}
        >
            <div>{props.id}</div>
            <p>
                <FormattedMessage id={`workflowStep.${props.label}`} />
            </p>
        </div>
    );
};

export default WorkflowStep;
