import React from "react";
import { BrowserRouter } from "react-router-dom";
import QueueContainer from "./containers/appointments/QueueContainer";
import ArrivedQueueContainer from "./containers/appointments/ArrivedQueueContainer";
import { Switch, Route } from "react-router-dom";
import HomeComponent from "./containers/home/HomeComponent";
import NavbarComponent from "./components/nav/NavbarComponent";
import FooterComponent from "./components/footer/FooterComponent";
import SettingsComponent from "./containers/settings/SettingsComponent";
import CalendarContainer from "./containers/calendar/CalendarContainer";
import UsersContainer from "./containers/users/UsersContainer";
import TimelineComponent from "./containers/timeline/TimelineComponent";
import OncallContainer from "./containers/oncall/OncallContainer";

function Router() {
    const basename = process.env.REACT_APP_ROUTER_BASENAME || "";

    return (
        <BrowserRouter basename={`/${basename}`}>
            <div className="d-flex">
                <div className="align-items-stretch">
                    <NavbarComponent />
                </div>
                <Switch>
                    <Route exact={true} path="/" component={HomeComponent} />
                    <Route path="/home" component={HomeComponent} />
                    <Route
                        exact={true}
                        path="/queue"
                        component={QueueContainer}
                    />
                    <Route path="/calendar" component={CalendarContainer} />
                    <Route path="/person" component={UsersContainer} />
                    <Route path="/settings" component={SettingsComponent} />
                    <Route path="/timeline" component={TimelineComponent} />
                    <Route path="/oncall" component={OncallContainer} />
                    <Route
                        path="/queue/arrived-queue"
                        component={ArrivedQueueContainer}
                    />
                </Switch>
            </div>
            <div className="align-self-end">
                <FooterComponent />
            </div>
        </BrowserRouter>
    );
}

export default Router;
