import { LOGIN, LOGOUT } from "../actions/user.actions";

const INITIAL_STATE = {
    isLogin: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isLogin: true,
                access_token: action.payload.access_token,
                refresh_token: action.payload.refresh_token,
            };
        case LOGOUT:
            return Object.assign({}, state, {
                isLogin: false,
                refresh_token: "",
                access_token: "",
            });
        default:
            return state;
    }
};

export default userReducer;
