import { FormattedMessage } from "react-intl";
import { getPatientAge } from "../../helpers/visit";

const columnFormatter = (cell) => {
    return <div className="spacer">{cell}</div>;
};
const userFormatter = (cell, row) => (
    <div className="spacer">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="currentColor"
            className="bi bi-person-fill float-left mt-2 mr-2"
            viewBox="0 0 16 16"
        >
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
        </svg>
        {`${row.first_name} ${row.last_name}`}
        <br />
        <span>M | {getPatientAge(row.dob)} | HGKADLK</span>
    </div>
);
const patientHeaderFormatter = (column, colIndex) => (
    <FormattedMessage id="calender.patient" />
);
export const columns = [
    {
        dataField: "uuid",
        text: "ID",
        sort: true,
        formatter: columnFormatter,
    },
    {
        dataField: "first_name",
        text: "Patient",
        sort: true,
        headerFormatter: patientHeaderFormatter,
        formatter: userFormatter,
    },
    {
        dataField: "dob",
        text: "Date of birth",
        sort: true,
        formatter: columnFormatter,
    },
];
