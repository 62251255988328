import React, { useEffect, useState } from "react";
import RowNavigation from "./rowNavigation";

const ExpandRowsView = ({ data, id, detailsView }) => {
    const [rowData, setRowData] = useState(null);

    useEffect(() => {
        const selectRow = data.data.filter((item) => item.uuid === id)[0];
        setRowData(selectRow);
    }, [data, id]);

    return (
        <>
            {rowData ? (
                <RowNavigation detailsView={detailsView(rowData)} />
            ) : null}
        </>
    );
};

export default ExpandRowsView;
