import Service from "./Api";

class Appointment extends Service {
    constructor() {
        super();
        this.url = "/api/appointment/:id/:action/";
        this.urlParams = {
            id: "@id",
            action: "@action",
        };
    }
}

class User extends Service {
    constructor() {
        super();
        this.url = "/api/user/:id/:action/";
        this.urlParams = {
            id: "@id",
            action: "@action",
        };
    }
}

class Workflow extends Service {
    constructor() {
        super();
        this.url = "/api/workflow/:id/:action/:actionId/";
        this.urlParams = {
            id: "@id",
            action: "@action",
            actionId: "@actionId",
        };
    }
}

class WorkflowStep extends Service {
    constructor() {
        super();
        this.url = "/api/workflow_step/:id/:action/:actionId/";
        this.urlParams = {
            id: "@id",
            action: "@action",
            actionId: "@actionId",
        };
    }
}

class WorkflowStepTemplate extends Service {
    constructor() {
        super();
        this.url = "/api/workflow_template_step/:id/:action/:actionId/";
        this.urlParams = {
            id: "@id",
            action: "@action",
            actionId: "@actionId",
        };
    }
}

class WorkflowForm extends Service {
    constructor() {
        super();
        this.url = "/api/workflow_form/:id/:action/";
        this.urlParams = {
            id: "@id",
            action: "@action",
        };
    }
}

class Schedule extends Service {
    constructor() {
        super();
        this.url = "/api/schedule/:uuid/";
        this.urlParams = {
            uuid: "@uuid",
        };
    }
}

class Location extends Service {
    constructor() {
        super();
        this.url = "/api/location/:uuid/";
        this.urlParams = {
            uuid: "@uuid",
        };
    }
}
class LocationUser extends Service {
    constructor() {
        super();
        this.url = "/api/location_user/:uuid/:action/";
        this.urlParams = {
            uuid: "@uuid",
            action: "@action",
        };
    }
}

class BookedSlots extends Service {
    constructor() {
        super();
        this.url = "/api/slot/";
        this.urlParams = {
            start_date_time: "@start_date_time",
            schedule_id: "@schedule_id",
        };
    }
}

class Login extends Service {
    constructor() {
        super();
        this.url = "/oauth/token/";
    }
}

export const AppointmentService = new Appointment();
export const ScheduleService = new Schedule();
export const LocationService = new Location();
export const LocationUserService = new LocationUser();
export const UserService = new User();
export const WorkflowService = new Workflow();
export const WorkflowStepService = new WorkflowStep();
export const WorkflowStepTemplateService = new WorkflowStepTemplate();
export const WorkflowFormService = new WorkflowForm();
export const BookedSlotsService = new BookedSlots();
export const LoginService = new Login();
