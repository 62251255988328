import { SET_CMETER } from "../actions/cmeter.actions";

const INITIAL_STATE = {
    cmeterStatus: [],
};

const cmeterReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CMETER:
            return {
                ...state,
                cmeterStatus: action.payload,
            };
        default:
            return state;
    }
};

export default cmeterReducer;
