import React from "react";

function FooterComponent() {
    return (
        <footer className="text-center text-lg-start">
            <div className="text-center p-3">
                © 2022 Copyright:
                <a href="#footer"> </a>
            </div>
        </footer>
    );
}

export default FooterComponent;
