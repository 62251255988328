import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Button } from "react-bootstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TableSettingsHeaderItem from "./TableSettingsHeaderItem";
import { UserSettingsService } from "./../../services/UserSettings";
import { FormattedMessage } from "react-intl";

const propTypes = {
    tableName: PropTypes.string.isRequired,
    tableHeaders: PropTypes.array.isRequired,
};

function TableSettingsComponent(props) {
    const SHOWED_HEADERS = `${props.tableName}_showed_headers`;
    const ORDER_HEADERS = `${props.tableName}_order_headers`;

    const [availableHeaders, setAvailableHeaders] = useState([]);
    const [headersOrder, setHeadersOrder] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const defaultHeaders = useCallback(() => {
        return props.tableHeaders.map((el) => {
            return {
                dataField: el,
                text: el,
                show: true,
            };
        });
    }, [props.tableHeaders]);

    useEffect(() => {
        async function getSavedSettings() {
            let showed = await UserSettingsService.getItem(SHOWED_HEADERS);
            showed = showed ? JSON.parse(showed) : [];
            showed = !!showed.length ? showed : defaultHeaders();
            setAvailableHeaders(showed);
            let order = await UserSettingsService.getItem(ORDER_HEADERS);
            order = order ? JSON.parse(order) : [];
            order = !!order.length ? order : [...showed];
            setHeadersOrder(order);
        }

        getSavedSettings();
    }, [SHOWED_HEADERS, ORDER_HEADERS, defaultHeaders]);

    async function saveHeadersOrder(headersOrder) {
        let order = JSON.stringify(headersOrder);
        await UserSettingsService.setItem(ORDER_HEADERS, order);
    }

    async function saveHeadersVisibility(showed) {
        let availbale = JSON.stringify(showed);
        await UserSettingsService.setItem(SHOWED_HEADERS, availbale);
    }

    async function saveHeaders() {
        setIsSaving(true);
        await saveHeadersVisibility(availableHeaders);
        await saveHeadersOrder(headersOrder);
        setIsSaving(false);
    }

    function toggleShowColumn(id) {
        let headers = [...availableHeaders];
        headers[id].show = !headers[id].show;
        setAvailableHeaders(headers);
        setHeadersOrder(headers.filter((el) => el.show));
    }

    function moveItem(dragIndex, hoverIndex) {
        let draggedItem = { ...headersOrder[dragIndex] };
        let items = [...headersOrder];
        items.splice(dragIndex, 1);
        let start = items.slice(0, hoverIndex);
        let end = items.slice(hoverIndex);
        items = [...start, draggedItem, ...end];
        setHeadersOrder(items);
    }

    return (
        <Container>
            <Row>
                <Col>
                    <div className="table-settings">
                        <FormattedMessage id="settings.calendar.availableColumns" />
                    </div>
                    <div className="colums-list">
                        {availableHeaders.map((el, key) => {
                            return (
                                <div className="column-name" key={key}>
                                    <input
                                        type="checkbox"
                                        onChange={() => toggleShowColumn(key)}
                                        defaultChecked={el.show}
                                    />
                                    {el.text}
                                </div>
                            );
                        })}
                    </div>
                </Col>
                <Col>
                    <div className="table-settings">
                        <FormattedMessage id="settings.calendar.selectedColumns" />
                    </div>
                    <DndProvider backend={HTML5Backend}>
                        <div className="draggable-list">
                            {headersOrder.map((el, key) => (
                                <TableSettingsHeaderItem
                                    key={el.text}
                                    index={key}
                                    id={el.text}
                                    text={el.text}
                                    moveItem={moveItem}
                                />
                            ))}
                        </div>
                    </DndProvider>
                </Col>
            </Row>
            <Row className="button-row">
                <Col>
                    <Button
                        disabled={isSaving}
                        onClick={!isSaving ? saveHeaders : null}
                    >
                        <FormattedMessage id="settings.calendar.save" />
                    </Button>
                </Col>
            </Row>
        </Container>
    );
}

TableSettingsComponent.propTypes = propTypes;
export default TableSettingsComponent;
