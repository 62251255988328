import React from "react";

function AppointmentEvent(props) {
    const info = props.eventInfo;
    const extProps = info.event.extendedProps;

    return (
        <div className="appointment-container">
            <div className="appointment-time-details">
                <span className="name">{info.event.title}</span>
                <span className="time">
                    {extProps.startTime} - {extProps.endTime}
                </span>
            </div>
            <div className="appointment-person">
                <span className="name">
                    {extProps.firstName} {extProps.lastName}{" "}
                    {extProps.dateOfBirth}
                </span>
            </div>
            <div className="appointment-status">{extProps.status}</div>
        </div>
    );
}

export default AppointmentEvent;
