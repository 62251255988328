import moment from "moment";
import React, { useState } from "react";
import { WorkflowStepService, WorkflowStepTemplateService } from "../../../../services/Resources";
import WorkflowStep from "./workflowStep";
import { FormattedMessage } from "react-intl";

const Workflow = ({ workflow }) => {
    const [steps, setSteps] = useState([]);
    const [showProgress, setShowProgress] = useState([]);

    const fetchWorkflowSteps = () => {
        const _data = WorkflowStepService.get(
            {
                params: {
                    workflow: workflow.uuid,
                },
            },
            (response) => {
                try {
                    const steps = response.results.map((step) => {
                        const singleStep = WorkflowStepTemplateService
                            .get({
                                params: {
                                    id: /((\w{4,12}-?)){6}/.exec(step.workflow_step_template)[0]
                                }
                            }, (resp) => {
                                const templateStep = {
                                    id: step.uuid,
                                    status: step.status,
                                    order: resp.order,
                                    step_name: resp.name
                                        .split(" ")
                                        .pop()
                                        .toLowerCase(),
                                };
                                setSteps((prev) => [...prev, templateStep]);
                            })
                        return singleStep;
                    });
                    return steps;
                } catch (error) {
                    console.log(error);
                }
            },
            (error) => console.log(error)
        );
        return _data;
    };

    const toggleShowSteps = (id) => {
        setShowProgress((prevMoreInfo) => ({
            [id]: !prevMoreInfo[id],
        }));
        if (!Object.values(showProgress)[0]) {
            fetchWorkflowSteps();
        } else setSteps([]);
    };
    const handleClick = () => {
        toggleShowSteps(workflow.uuid);
    };

    const capitalizeFirstLetter = (item) => {
        const string = item.replace("_", " ");
        return string
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
    };

    const displayWorkflowSteps = () => {
        if (steps.length !== 0) {
            return steps
                .sort((a, b) => (a.order > b.order ? 1 : -1))
                .map((step) => {
                    const { id, step_name, order, status } = step;
                    return (
                        <WorkflowStep
                            key={id}
                            label={step_name}
                            id={order}
                            status={status}
                        />
                    );
                });
        } else return null;
    };

    return (
        <React.Fragment key={workflow.uuid}>
            <div className="d-flex align-items-center mb-3">
                <div>
                    {capitalizeFirstLetter(workflow.workflow_template_slug)}:{" "}
                    {moment(workflow.created_at).format("LLLL")}
                </div>
                <button
                    className=" btn btn-light ml-3"
                    onClick={(e) => handleClick()}
                >
                    <FormattedMessage id="expandRow.tabsName.progress.button" />
                </button>
            </div>
            <div>
                {showProgress[workflow.uuid] ? displayWorkflowSteps() : null}
            </div>
        </React.Fragment>
    );
};

export default Workflow;
