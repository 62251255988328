import React, { useState } from "react";
import { loggingIn } from "./../../redux/actions/user.actions";
import { FormattedMessage } from "react-intl";
import RedLeafLabsLogo from "./img/rll-mobile-logo.png";
import { useDispatch, useSelector } from "react-redux";
import LangButton from "../buttons/langButtons/langButton";
import ConnectionButton from "../buttons/connectionButton/connectionButton";
import { emailRegex } from "./../../constans/validationRules";

function LoginPage() {
    const [username, setUsername] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);
    const isLogin = useSelector((state) => state.user.isLogin);
    const dispatch = useDispatch();

    const validation = () => {
        let result = true;

        if (!username) {
            setUsernameError("errors.usernameEmpty");
            result = false;
        }
        if (!password) {
            setPasswordError("errors.passwordEmpty");
            result = false;
        }

        const isEmailValid = emailRegex.test(username);
        if (!isEmailValid && username) {
            setUsernameError("errors.usernameError");
            result = false;
        }
        return result;
    };

    const handleUsernameChange = (e) => {
        setUsernameError("");
        setUsername(e.target.value);
    };
    const handlePasswordChange = (e) => {
        setPasswordError("");
        setPassword(e.target.value);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const isValid = validation();
        if (isValid) {
            await dispatch(loggingIn({ username, password }));
        }
        if (!isLogin && isValid) {
            setIsErrorPopupVisible(true);
            return;
        }
        setIsErrorPopupVisible(false);
    };
    return (
        <div className="login-wrapper d-flex flex-column">
            <div className="text-center login-img-wrapper">
                <img
                    alt="Red leaf labs logo"
                    src={RedLeafLabsLogo}
                    className="img-fluid"
                />
            </div>
            {isErrorPopupVisible && (
                <div className="error-login">
                    <h5>
                        <FormattedMessage id="errors.popup.loginFailedTitle" />
                    </h5>
                    <p>
                        <FormattedMessage id="errors.popup.loginFailed" />
                    </p>
                </div>
            )}

            <form onSubmit={handleFormSubmit}>
                <div className="login-control">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className={
                            usernameError
                                ? "bi bi-envelope e-mail-icon-error"
                                : "bi bi-envelope e-mail-icon"
                        }
                        viewBox="0 0 16 16"
                    >
                        <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z" />
                    </svg>
                    <FormattedMessage id="kiosk.username">
                        {(placeholder) => (
                            <input
                                className={usernameError && "input-on-error"}
                                type="text"
                                name="username"
                                placeholder={placeholder}
                                value={username}
                                onChange={handleUsernameChange}
                            />
                        )}
                    </FormattedMessage>
                    {usernameError && (
                        <div className="error-msg">
                            <FormattedMessage id={usernameError} />
                        </div>
                    )}
                </div>
                <div className="login-control">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className={
                            passwordError
                                ? "bi bi-lock-fill padlock-icon-error"
                                : "bi bi-lock-fill padlock-icon"
                        }
                        viewBox="0 0 16 16"
                    >
                        <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z" />
                    </svg>
                    <FormattedMessage id="kiosk.password">
                        {(placeholder) => (
                            <input
                                className={passwordError && "input-on-error"}
                                type="password"
                                name="password"
                                placeholder={placeholder}
                                value={password}
                                autoComplete="new-password"
                                onChange={handlePasswordChange}
                            />
                        )}
                    </FormattedMessage>
                    {passwordError && (
                        <div className="error-msg">
                            <FormattedMessage id={passwordError} />
                        </div>
                    )}
                </div>
                <div className="login-control">
                    <FormattedMessage id="buttons.login">
                        {(placeholder) => (
                            <input
                                type="submit"
                                name="login"
                                value={placeholder}
                                className="btn btn-outline-light login-btn login-control"
                            />
                        )}
                    </FormattedMessage>
                </div>
            </form>
        </div>
    );
}

function LoginComponent(props) {
    return (
        <div className="login-component d-flex justify-content-center align-content-center">
            <ConnectionButton />
            <LangButton />
            {LoginPage(props)}
        </div>
    );
}

export default LoginComponent;
