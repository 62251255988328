import React from "react";
import Collapsible from "react-collapsible";

export const LocationCollapsibleWrapper = ({
    children,
    previewDate,
    setPreviewDate,
    location,
    onOpen,
    onClose,
}) => {
    return (
        <Collapsible
            onOpen={onOpen}
            onClose={onClose}
            transitionTime={300}
            contentInnerClassName="locationPreview__CollapsibleInner"
            trigger={
                <div className="locationPreview__nameSection">
                    <div className="locationPreview__info">
                        <div className="locationPreview__locationName">
                            {location?.name}&nbsp;
                        </div>
                        <div className="locationPreview__locationAddress">
                            {location?.address}&nbsp;
                        </div>
                    </div>
                </div>
            }
        >
            {children}
        </Collapsible>
    );
};
