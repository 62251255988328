import React, { useState, useMemo, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ScheduleService } from "../../services/Resources";
import { useIntl } from "react-intl";
import LocationUserWrapper from "./LocationUserWrapper";
import { LocationUserService } from "../../services/Resources";
import { LocationCollapsibleWrapper } from "./LocationCollapsibleWrapper";
import { Spinner } from "react-bootstrap";
import moment from "moment";

export default function LocationPreview({ location }) {
    const intl = useIntl();
    const [dateNow, setDateNow] = useState(moment().format().split("T")[0]);
    const [entities, setEntities] = useState([]);
    const [hasBeenOpened, setHasBeenOpened] = useState(false);
    const [startDatetime, setStartDatetime] = useState();
    const [endDatetime, setEndDatetime] = useState();
    const [isClicked, setIsClicked] = useState(false);
    const dateMax = moment().add(14, "days").format().split("T")[0];

    const showToday = () => {
        setStartDatetime(dateNow + "T00:00:00+00:00");
        setEndDatetime(dateNow + "T23:59:59+00:00");
    };

    const showTwoWeeks = () => {
        setStartDatetime(dateNow + "T00:00:00+00:00");
        setEndDatetime(dateMax + "T23:59:59+00:00");
    };

    useEffect(() => {
        if (entities.length > 0 || hasBeenOpened === false) return;
        showToday();
        pullEntities();
    }, [hasBeenOpened]);

    useEffect(() => {
        setIsClicked(!isClicked);
    }, [startDatetime, endDatetime]);

    const pullEntities = () => {
        LocationUserService.get(
            {
                params: {
                    location__uuid: location.uuid,
                },
            },
            (data) => {
                setEntities(data.results);
            }
        );
    };

    const translations = useMemo(() => {
        const translations = {};
        Object.keys(intl.messages).forEach((key) => {
            const keyParts = key.split(".");
            if (keyParts[0] === "timelineWidget") {
                translations[keyParts[1]] = intl.formatMessage({ id: key });
            }
        });

        return translations;
    }, [intl]);

    const updateSchedule = async (scheduleData) => {
        let result = {
            status: false,
            info: intl.formatMessage({
                id: "timelineWidget.scheduleUpdateError",
            }),
        };

        await ScheduleService.patch(
            {
                params: {
                    uuid: scheduleData.uuid,
                },
                data: {
                    ...scheduleData,
                },
            },
            () => {
                result = {
                    status: true,
                    info: "",
                };
            }
        );
        return result;
    };

    const postSchedule = async (scheduleData, entity_uuid) => {
        let result = {
            status: false,
            info: intl.formatMessage({
                id: "timelineWidget.scheduleCreateError",
            }),
        };
        await ScheduleService.post(
            {
                data: {
                    ...scheduleData,
                    timezone: "Canada/Central",
                    group: location.uuid,
                    name: null,
                    entity: entity_uuid,
                },
            },
            () => {
                result = {
                    status: true,
                    info: "",
                };
            }
        );

        return result;
    };

    const renderRows = () => {
        if (!entities) return [];
        const rows = [];
        entities.forEach((entity, i) => {
            rows.push(
                <div className="locationPreview__row" key={`row-${i}`}>
                    <LocationUserWrapper
                        entity={entity}
                        date={dateNow}
                        updateSchedule={updateSchedule}
                        postSchedule={postSchedule}
                        translations={translations}
                        startDatetime={startDatetime}
                        endDatetime={endDatetime}
                    />
                </div>
            );
        });

        return rows;
    };
    return (
        <div className="locationPreview">
            <LocationCollapsibleWrapper
                previewDate={dateNow}
                setPreviewDate={setDateNow}
                location={location}
                onOpen={() => {
                    setHasBeenOpened(true);
                }}
            >
                <div className="locationPreview__displayDate">
                    <div
                        class="btn-group"
                        role="group"
                        aria-label="Basic example"
                    >
                        <button
                            className={
                                isClicked
                                    ? "unClicked button-left-timeline"
                                    : "onClicked button-left-timeline"
                            }
                            value={dateNow}
                            onClick={showToday}
                        >
                            Today
                        </button>
                        <button
                            className={
                                !isClicked
                                    ? "unClicked button-right-timeline"
                                    : "onClicked button-right-timeline"
                            }
                            onClick={showTwoWeeks}
                        >
                            Show More
                        </button>
                    </div>
                </div>
                <div className="locationPreview__rowsWrapper">
                    {entities.length ? (
                        renderRows()
                    ) : (
                        <div className="locationPreview__loading">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    )}
                </div>
            </LocationCollapsibleWrapper>
        </div>
    );
}
