import axios from "axios";
import { loggingOut } from "./../redux/actions/user.actions";
import store from "./../redux/store";
import { LoginService } from "./Resources";
const API_URL = process.env.REACT_APP_API_URL;

const ERROR_CODES = {};

function encodeUriQuery(val, pctEncodeSpaces) {
    return encodeURIComponent(val)
        .replace(/%40/gi, "@")
        .replace(/%3A/gi, ":")
        .replace(/%24/g, "$")
        .replace(/%2C/gi, ",")
        .replace(/%3B/gi, ";")
        .replace(/%20/g, pctEncodeSpaces ? "%20" : "+");
}

function encodeUriSegment(val) {
    return encodeUriQuery(val, true)
        .replace(/%26/gi, "&")
        .replace(/%3D/gi, "=")
        .replace(/%2B/gi, "+");
}

class Service {
    constructor() {
        let service = axios.create();
        this.url = "";
        this.urlParams = {};
        this.service = service;
        this.service.interceptors.response.use(
            Service.handleSuccess,
            this.handleError
        );
        this.service.interceptors.request.use(this.setHeaders);
        this.subscriptionError = null;
    }

    setHeaders = (config) => {
        let token = this.getToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
        }
        return config;
    };

    getToken = () => {
        let token = localStorage.getItem("access");
        return token;
    };

    getRefreshToken = () => {
        let refresh = localStorage.getItem("refresh");
        return refresh;
    };

    getPath = (params) => {
        let urlParams = (this.urlParams = Object.create(null));
        let url = API_URL + this.url;
        url.split(/\W/).forEach(function (param) {
            if (
                !new RegExp("^\\d+$").test(param) &&
                param &&
                new RegExp("(^|[^\\\\]):" + param + "(\\W|$)").test(url)
            ) {
                urlParams[param] = {
                    isQueryParamValue: new RegExp(
                        "\\?.*=:" + param + "(?:\\W|$)"
                    ).test(url),
                };
            }
        });
        url = url.replace(/\\:/g, ":");

        params = params || {};

        for (let urlParam in urlParams) {
            let paramInfo = urlParams[urlParam];
            let encodedVal;
            let val = params.hasOwnProperty(urlParam) ? params[urlParam] : null;
            if (val !== null && val !== undefined) {
                if (paramInfo.isQueryParamValue) {
                    encodedVal = encodeUriQuery(val, true);
                } else {
                    encodedVal = encodeUriSegment(val);
                }
                url = url.replace(
                    new RegExp(":" + urlParam + "(\\W|$)", "g"),
                    function (match, p1) {
                        return encodedVal + p1;
                    }
                );
            } else {
                url = url.replace(
                    new RegExp("(/?):" + urlParam + "(\\W|$)", "g"),
                    function (match, leadingSlashes, tail) {
                        if (tail.charAt(0) === "/") {
                            return tail;
                        } else {
                            return leadingSlashes + tail;
                        }
                    }
                );
            }
        }
        return url;
    };

    static handleSuccess(response) {
        return response;
    }

    handleError = (error) => {
        let error_code = 404;
        if (error.response) {
            error_code = error.response.status;
        }

        switch (error_code) {
            case 400:
                // TODO - error handler
                console.log("bad request");
                break;
            case 403:
                // TODO - error handler
                console.log("unauthorized");
                break;
            case 401:
                const refresh_token = this.getRefreshToken() || "";

                let formData = {
                    refresh_token,
                    grant_type: "refresh_token",
                    client_id: process.env.REACT_APP_CLIENT_ID,
                };

                let data = Object.keys(formData)
                    .map((key) => `${key}=${encodeURIComponent(formData[key])}`)
                    .join("&");

                const options = {
                    params: {
                        enabled: undefined,
                    },
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data,
                };
                LoginService.post(
                    options,
                    ({ access_token, refresh_token }) => {
                        localStorage.setItem("access", access_token);
                        localStorage.setItem("refresh", refresh_token);

                        window.location.reload();
                    },
                    () => {
                        store.dispatch(loggingOut());
                    }
                );
                break;

            default:
                if (store.getState().isLogin) {
                    store.dispatch(loggingOut());
                }
                console.log(error.response);
                break;
        }

        return Promise.reject(error);
    };

    getUrlParams = (params) => {
        let urlParams = this.urlParams;
        let results = {};
        for (let param in params) {
            if (!urlParams[param]) {
                results[param] = params[param];
            }
        }
        return results;
    };

    get(options, success, failure) {
        return this._request(options, "GET", success, failure);
    }

    patch(options, success, failure) {
        return this._request(options, "PATCH", success, failure);
    }

    post(options, success, failure) {
        return this._request(options, "POST", success, failure);
    }

    delete(options, success, failure) {
        return this._request(options, "DELETE", success, failure);
    }

    _get_signal(options) {
        try {
            return options.signal.token;
        } catch (e) {
            return null;
        }
    }

    _request(options, method, success, failure) {
        return this.service
            .request({
                method: method,
                url: this.getPath(options.params),
                params: {
                    enabled: true,
                    ...options.params,
                },
                headers: axios.defaults.headers,
                cancelToken: this._get_signal(options),
                responseType: "json",
                data: options.data,
            })
            .then(
                (response) => {
                    if (success) {
                        success(response.data, response.status);
                    }
                },
                (e) => {
                    if (failure) {
                        failure(e);
                    }
                }
            );
    }
}

export default Service;
