import moment from "moment";
import "moment-timezone";

export const getPatientAge = (user_dob) => {
    return moment().diff(moment(user_dob, "DD-MM-yyyy"), "years");
};

export const getVisitStart = (visit_start) => {
    return moment(visit_start).format("hh:mm");
};

export const getVisitTime = (visit_start, visit_end) => {
    return (
        (moment(visit_end).format("hh") - moment(visit_start).format("hh")) *
            60 +
        moment(visit_end).format("mm") -
        moment(visit_start).format("mm") +
        " min"
    );
};

export const getTimezone = () => {
    return moment.tz(moment.tz.guess()).zoneAbbr();
};
