import React from "react";
import { useSelector } from "react-redux";
import { I18nProvider } from "../../i18n/index";
import { DEFAULT_LANGUAGE } from "../../i18n/configuration";

const LanguageProvider = ({ children }) => {
    const language = useSelector((state) => state.language.selectedLanguage);
    const selectedLanguage = language ? language : DEFAULT_LANGUAGE.ENGLISH;
    return <I18nProvider locale={selectedLanguage}>{children}</I18nProvider>;
};

export default LanguageProvider;
