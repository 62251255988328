import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLanguageAsyc } from "./redux/actions/language.actions";
import Router from "./Router";
import LoginComponent from "./components/login/LoginComponent";
import { setTokens } from "./redux/actions/user.actions";
import { setCmeter } from "./redux/actions/cmeter.actions";
import { createMeter } from "./components/configCmeter/configMeter";

function App() {
    const appLoading = useSelector((state) => state.language.isFetching);
    const isLogin = useSelector((state) => state.user.isLogin);
    const dispatch = useDispatch();

    const access_token = localStorage.getItem("access");
    const refresh_token = localStorage.getItem("refresh");

    if (access_token && refresh_token) {
        dispatch(setTokens({ access_token, refresh_token }));
    }

    useEffect(() => {
        dispatch(fetchLanguageAsyc());
    }, [dispatch]);

    useEffect(() => {
        const cmeterInstance = createMeter();
        cmeterInstance.onStatusUpdate = (status) => {
            dispatch(setCmeter(status));
        };
        cmeterInstance.start();

        setInterval(() => {
            if (!cmeterInstance.isBusy()) {
                cmeterInstance.start();
            }
        }, 60000);
    }, []); // eslint-disable-line

    if (appLoading) return <div className="spinner-border" role="status" />;

    if (!isLogin) return <LoginComponent />;

    return <Router />;
}

export default App;
