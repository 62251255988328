import { LOCALES } from "../constants";

export const German = {
    [LOCALES.GERMAN]: {
        buttons: {
            login: "Anmeldung",
            logout: "Ausloggen",
        },
        kiosk: {
            username: "Nutzername",
            password: "Passwort",
            new: "Neuen Account erstellen",
            forgot: "Passwort vergessen?",
            searchInput: "Suche...",
            popup: {
                timeout: "Auszeit in",
                seconds: "Sekunden",
                continue: "Fortsetzen",
            },
        },
        errors: {
            usernameError: "Ungültiger Benutzername",
            usernameEmpty: "Benutzername erforderlich",
            passwordEmpty: "Passwort erforderlich",
            popup: {
                loginFailedTitle: "Fehler bei der Anmeldung",
                loginFailed:
                    "Bitte geben Sie einen korrekten Benutzernamen und ein Passwort ein. ",
            },
        },
        menu: {
            home: "Zuhause",
            homepage: "Startseite",
            activeQueue: "Aktive Warteschlange",
            calendar: "Kalender",
            person: "Person",
            settings: "die Einstellungen",
            timeline: "Zeitleiste",
            logout: "Ausloggen",
            oncall: "Bei Anruf",
        },
        hiddenMenu: {
            options: {
                one: "Unteroption 1",
                two: "Unteroption 2",
                three: "Unteroption 3",
                four: "Unteroption 4",
            },
        },
        calender: {
            today: "Heute",
            queue: "in der Warteschlange eingecheckt",
            status: "Status",
            patient: "Geduldig",
            renderingProvider: "Rendering-Anbieter",
            visitType: "Besuchen Sie Type",
            payments: "Zahlungen",
            actions: {
                action: "Aktionen",
                actionOne: "Aktionen 1",
                actionTwo: "Aktionen 2",
                actionThree: "Aktionen 3",
            },
            dropdownItem: {
                status: {
                    all: "alle Status",
                    scheduled: "geplant",
                    completed: "abgeschlossen",
                    arrived: "ist eingetroffen",
                },
                visitType: {
                    one: "Status 1",
                    two: "Status 2",
                    three: "Status 3",
                },
            },
        },
        expandRow: {
            tabsName: {
                progress: {
                    name: "Fortschritt",
                    button: "Zeig mehr",
                },
            },
        },
        workflowStep: {
            service: "Bedienung",
            location: "Ort",
            time: "Zeit",
            registration: "Registrieren",
            confirmation: "Bestätigt",
            lookup: "Nachschlagen",
        },
        timelineWidget: {
            ok: "Ok",
            scheduleUpdateError:
                "Der Zeitplan konnte nicht aktualisiert werden. Bitte versuchen Sie es später erneut.",
            scheduleCreateError:
                "Fehler beim Erstellen des Zeitplans. Bitte versuchen Sie es später erneut.",
            create: "Creëren",
            update: "Aktualisieren",
            edit: "Bearbeiten",
            datePreview: "Datum",
            cancel: "Stornieren",
            monday: "Montag",
            tuesday: "Dienstag",
            wednesday: "Mittwoch",
            thursday: "Donnerstag",
            friday: "Freitag",
            saturday: "Samstag",
            sunday: "Sonntag",
            cancelModalBody:
                "Sie haben nicht gespeicherte Änderungen, wenn Sie die Bearbeitung abbrechen, gehen sie unwiederbringlich verloren ",
            cancelModalAbort: "Zurück zur Bearbeitung ",
            cancelModalProceed: "Änderungen verwerfen",
        },
        settings: {
            changeLang: "Sprache ändern",
            french: "Français",
            german: "Deutsche",
            spanish: "Española",
            english: "English",
            polish: "Polski",
            japanese: "日本語",
            calendar: {
                save: "Speichern",
                availableColumns: "Verfügbare Spalten",
                selectedColumns: "Ausgewählte Spalten",
            },
        },
    },
};
