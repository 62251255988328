import React, { useState } from "react";
import ButtonImg from "./img/connectionButton.svg";
import CheckConnection from "../../../containers/checkConnection/CheckConnection";

const ConnectionButton = () => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="connectionButton">
            {isOpen ? <CheckConnection setIsOpen={setIsOpen} /> : null}
            <img
                className="connectionIcon"
                onClick={() => setIsOpen(!isOpen)}
                alt="connection"
                src={ButtonImg}
            />
        </div>
    );
};

export default ConnectionButton;
