import React, { useCallback, useEffect, useState } from "react";
import { ScheduleService } from "../../services/Resources";
import { CollapsibleWrapper } from "./CollapsibleWrapper";
import ScheduleWrapper from "./ScheduleWrapper";
import { Spinner } from "react-bootstrap";
import ScheduleWizard from "./ScheduleWizard";

export default function LocationUserWrapper({
    entity,
    date,
    updateSchedule,
    postSchedule,
    translations,
    startDatetime,
    endDatetime,
}) {
    const [schedules, setSchedules] = useState();
    const [processing, setProcessing] = useState(false);
    const [isOpened, setIsOpened] = useState(false);
    const [hasBeenOpened, setHasBeenOpened] = useState(false);
    const name = `${entity.user_first_name} ${entity.user_last_name} `;

    const setOpenStatus = () => {
        setIsOpened(true);
        if (hasBeenOpened) return;
        setHasBeenOpened(true);
    };

    const setCloseStatus = () => {
        setIsOpened(false);
    };
    const pullSchedules = useCallback(() => {
        ScheduleService.get(
            {
                params: {
                    entity: entity.uuid,
                    start_date_time__gte: startDatetime,
                    end_date_time__lte: endDatetime,
                },
            },
            (data) => {
                setSchedules(data.results);
            }
        );
    }, [setSchedules, startDatetime, endDatetime]); // eslint-disable-line

    useEffect(() => {
        if (!hasBeenOpened) return;

        pullSchedules();
    }, [hasBeenOpened, pullSchedules, date]);

    useEffect(() => {
        if (!isOpened) {
            return;
        }

        pullSchedules();
    }, [isOpened, startDatetime, endDatetime]);

    const postNewSchedule = async (scheduleData, setWizardVisibility) => {
        setProcessing(true);

        const result = await postSchedule(scheduleData, entity.uuid);
        if (result.status) {
            setWizardVisibility(false);
            pullSchedules();
        }

        setProcessing(false);
        return result;
    };

    const genSchedules = () => {
        if (!schedules) return null;

        return schedules.map((schedule, i) => {
            return (
                <ScheduleWrapper
                    key={`schedule-${schedule.uuid}`}
                    date={date}
                    schedule={schedule}
                    updateSchedule={updateSchedule}
                    translations={translations}
                />
            );
        });
    };

    return (
        <CollapsibleWrapper
            name={name}
            onOpen={setOpenStatus}
            onClose={setCloseStatus}
        >
            {schedules ? (
                <>
                    {genSchedules()}
                    <div
                        className="locationPreview__loading"
                        style={{ display: !processing ? "none" : "flex" }}
                    >
                        <Spinner animation="border" variant="primary" />
                    </div>
                    <div style={{ display: processing ? "none" : "block" }}>
                        <ScheduleWizard
                            postSchedule={postNewSchedule}
                            translations={translations}
                        />
                    </div>
                </>
            ) : (
                <div className="locationPreview__loading">
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
        </CollapsibleWrapper>
    );
}
