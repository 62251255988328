import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

function HiddenOptionsMenuComponent(props) {
    const [isShown, setisShown] = useState(false);

    function toggleisShown() {
        if (isShown) {
            document.querySelector(".hidden-menu").classList.remove("clicked");
        } else {
            document.querySelector(".hidden-menu").classList.add("clicked");
        }
        setisShown(!isShown);
    }

    return (
        <div className="hidden-menu">
            <div>
                <p onClick={() => toggleisShown()}>
                    <Link to="/queue" onClick={() => toggleisShown()}>
                        <FormattedMessage
                            id={"menu." + props.element.infoText}
                        />
                    </Link>
                    {isShown ? (
                        <i className="arrow left"></i>
                    ) : (
                        <i className="arrow right"></i>
                    )}
                </p>
                {props.element.options.map((option) => (
                    <div key={props.element.name + option.optionsName}>
                        <Link
                            to={props.element.link + option.optionsLink}
                            onClick={() => toggleisShown()}
                        >
                            {option.optionsName}
                        </Link>
                        <br />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default HiddenOptionsMenuComponent;
