import React from "react";

const SearchBar = ({ searchData }) => {
    return (
        <div className="mb-3">
            <input
                onChange={(e) => searchData(e)}
                type="search"
                className="form-control"
                placeholder="Search..."
            ></input>
        </div>
    );
};

export default SearchBar;
