import React, { useState, useEffect } from "react";
import { LocationService } from "../../services/Resources";
import LocationPreview from "./LocationPreview";

export default function TimelineComponent() {
    const [entities, setEntities] = useState(null);
    const [locations, setLocations] = useState(null);

    useEffect(() => {
        LocationService.get({}, (data) => {
            setLocations(data.results);
        });
    }, []);

    const renderLocationPreviews = () => {
        if (!locations) return null;
        return locations.map((location, index) => {
            return (
                <LocationPreview
                    key={index}
                    entities={entities}
                    location={location}
                />
            );
        });
    };

    return <div className="timelineComponent">{renderLocationPreviews()}</div>;
}
