import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

const RowNavigation = ({ detailsView }) => {
    const [state, setState] = useState(0);

    const component = detailsView[state].component;

    const navigation = detailsView.map((item, index) => {
        return <Tab key={index} eventKey={index} title={item.tabName} />;
    });

    return (
        <>
            <Tabs
                className="mb-3"
                activeKey={state}
                onSelect={(eventKey) => setState(eventKey)}
            >
                {navigation}
            </Tabs>
            {component}
        </>
    );
};

export default RowNavigation;
